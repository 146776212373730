import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { NavigationConfig } from "@/types/NavigationConfig";
import { VersionConfig } from "@/types/VersionConfig";
import { Outlet, RouteObject } from "react-router-dom";

export default {
  id: "customer",
  name: "Customer Management",
  enabled: true,
  routes: [
    {
      path: "/app/customer",
      element: <Outlet />,
      children: [
        {
          index: true,
          async lazy() {
            const { CustomerPage: customer } = await import("./pages/CustomerPage");
            return { Component: customer };
          },
        },
        {
          path: ":action/:id?",
          async lazy() {
            const { CustomerForm } = await import("./modules/CustomerForm");
            return { Component: CustomerForm };
          },
        },
      ],
    },
  ] satisfies RouteObject[],
  versions: [{ version: "1.0.0" }] as VersionConfig[],
  activeVersion: "dev", // Set the active version for A/B testing or staging
  navigation: [
    {
      id: "customer",
      title: "Customer Management",
      icon: <SvgIcon name="user" width={16} height={16}/>,
      path: "/app/customer",
    },
  ] satisfies NavigationConfig[],
} as const satisfies FeatureMetadata;
