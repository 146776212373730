import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { RouteObject } from "react-router-dom";

const hotelRoutes = [
  {
    path: "/app/reservation/hotel",
    children: [
      {
        index: true,
        lazy: () => import("./pages/HotelPage"),
      },
      {
        path: "book/:productCode",
        element: <>TODO</>,
      },
    ],
  },
] satisfies RouteObject[];

const landArrangementRoutes = [
  {
    path: "/app/reservation/land-arrangment",
    children: [
      {
        index: true,
        lazy: () => import("./pages/LandArrangementPage"),
      },
      {
        path: "book/:productCode",
        lazy: () => import("./modules/LandArrangement"),
      },
    ],
  },
] satisfies RouteObject[];

const tourPackageRoutes = [
  {
    path: "/app/reservation/tour-package",
    children: [
      {
        index: true,
        lazy: () => import("./pages/TourPackagePage"),
      },
      {
        path: "book/:productCode",
        element: <>TODO</>,
      },
    ],
  },
] satisfies RouteObject[];

const flightRoutes = [
  {
    path: "/app/reservation/flights",
    children: [
      {
        index: true,
        lazy: () => import("./pages/FlightPage"),
      },
      {
        path: "book/:productCode",
        element: <>TODO</>,
      },
    ],
  },
] satisfies RouteObject[];

const visaRoutes = [
  {
    path: "/app/reservation/visa",
    children: [
      {
        index: true,
        lazy: () => import("./pages/VisaPage"),
      },
      {
        path: "book/:productCode",
        element: <>TODO</>,
      },
    ],
  },
] satisfies RouteObject[];

const routes = [
  ...hotelRoutes,
  ...landArrangementRoutes,
  ...tourPackageRoutes,
  ...flightRoutes,
  ...visaRoutes,
] satisfies RouteObject[];

export default {
  id: "reservasi",
  name: "Reservasi",
  routes,
  navigation: [
    {
      id: "reservasi",
      title: "Reservasi",
      icon: <SvgIcon name="book-marked" width={16} height={16} />,
      children: [
        {
          id: "reservasi-hotel",
          title: "Hotel",
          path: "/app/reservation/hotel",
        },
        {
          id: "reservasi-land-arrangment",
          title: "Land Arrangment",
          path: "/app/reservation/land-arrangment",
        },
        {
          id: "reservasi-tour-package",
          title: "Paket Tour",
          path: "/app/reservation/tour-package",
        },
        {
          id: "reservasi-flight",
          title: "Penerbangan",
          path: "/app/reservation/flights",
        },
        {
          id: "reservasi-visa",
          title: "Visa",
          path: "/app/reservation/visa",
        },
      ],
    },
  ],
} satisfies FeatureMetadata;
