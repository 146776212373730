import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { RouteObject } from "react-router-dom";

export default {
  id: "transaksi",
  name: "Transaksi",
  routes: [
    {
      path: "/app/transaksi",
      lazy: () => import("./pages/TransactionPage"),
    },
  ] satisfies RouteObject[],
  navigation: [
    {
      id: "transaction",
      path: "/app/transaksi",
      title: "Transaksi", 
      icon: <SvgIcon name="credit-card" width={16} height={16} />,
    },
  ],
} satisfies FeatureMetadata;
