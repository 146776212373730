import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { Outlet, RouteObject } from "react-router-dom";

export default {
  id: "users",
  name: "User Management",
  routes: [
    {
      path: "/app/users",
      element: <Outlet />,
      children: [
        {
          index: true,
          async lazy() {
            const { Component } = await import("./pages/UserPage");
            return { Component };
          },
        },
        {
          path: ":action/:id?",
          async lazy() {
            const { UserForm } = await import("./modules/UserForm");
            return { Component: UserForm };
          },
        },
      ],
    },
  ] satisfies RouteObject[],
  navigation: [
    {
      id: "users",
      path: "/app/users",
      title: "User Management", 
      icon: <SvgIcon name="user-cog" width={16} height={16} />,
    },
  ],
} satisfies FeatureMetadata;
