import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { Outlet, RouteObject } from "react-router-dom";

export default {
  id: "client",
  name: "Client Management",
  routes: [
    {
      path: "/app/client",
      element: <Outlet />,
      children: [
        {
          index: true,
          async lazy() {
            const { ClientPage: client } = await import("./pages/ClientPage");
            return { Component: client };
          },
        },
        {
          path: ":action/:id?",
          async lazy() {
            const { ClientForm } = await import("./modules/ClientForm");
            return { Component: ClientForm };
          },
        },
      ],
    },
  ] satisfies RouteObject[],
  navigation: [
    {
      id: "client",
      path: "/app/client",
      title: "Client Management",
      icon: <SvgIcon name="users" width={16} height={16} />,
    },
  ],
} satisfies FeatureMetadata;
