import { RouteObject } from "react-router-dom";
import { FeatureMetadata } from "../../types/FeatureMetadata";

export default {
  id: "Shell",
  name: "Shell",
  routes: [
    {
      path: "/app",
      lazy: () => import("./modules/AppShell"),
      children: [
        {
          path: "/app/dashboard",
          lazy: () => import("./pages/Dashboard"),
        },
      ] as RouteObject[],
    },
  ] satisfies RouteObject[],
} satisfies FeatureMetadata;
