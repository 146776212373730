import { ColorSchemeScript, MantineProvider, localStorageColorSchemeManager } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/tiptap/styles.css";
import "@mantine/carousel/styles.css";
import { defaultTheme } from "./defaultTheme";

export const colorSchemeManager = localStorageColorSchemeManager({
  key: "abt-color-scheme",
});

export const ThemeProvider = (props: { children: React.ReactNode }) => {
  return (
    <>
      <MantineProvider
        classNamesPrefix="abt"
        defaultColorScheme="light"
        theme={defaultTheme}
        colorSchemeManager={colorSchemeManager}
      >
        <ModalsProvider modalProps={{ radius: "md", withinPortal: true, centered: true }}>
          {props.children}
          <Notifications />
        </ModalsProvider>
      </MantineProvider>
    </>
  );
};
