import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { RouteObject } from "react-router-dom";

export default {
  id: "voucher",
  name: "Voucher",
  routes: [
    {
      path: "/app/voucher",
      children: [
        {
          index: true,
          lazy: () => import("./pages/VouchersPage"),
        },
        {
          path: ":action/:id?",
          lazy: () => import("./pages/VoucherEditorPage"),
          errorElement: <div>404</div>,
        },
      ],
    },
  ] satisfies RouteObject[],
  navigation: [
    {
      id: "voucher",
      path: "/app/voucher",
      title: "Voucher Management", 
      icon: <SvgIcon name="ticket" width={16} height={16} />,
    },
  ],
} satisfies FeatureMetadata;
