import { createTheme } from "@mantine/core";

export const defaultTheme = createTheme({
  fontFamily: "Poppins, sans-serif",
  headings: {
    fontFamily: "Inter, sans-serif",
  },
  primaryColor: "yellow",
  primaryShade: 5,
  defaultRadius: "md",
  components: {},
});
