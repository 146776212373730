import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { RouteObject } from "react-router-dom";

export default {
  id: "master-data",
  name: "Data Master",
  routes: [
    {
      path: "/app/master-data/airlines",
      children: [
        {
          index: true,
          lazy: () => import("./pages/AirlinesPage"),
        },
        {
          path: ":action/:id?",
          lazy: () => import("./modules/Airlines"),
        },
      ],
    },
    {
      path: "/app/master-data/airport",
      children: [
        {
          index: true,
          lazy: () => import("./pages/AirportPage"),
        },
        {
          path: ":action/:id?",
          lazy: () => import("./modules/Airport"),
        },
      ],
    },
  ] satisfies RouteObject[],
  navigation: [
    {
      id: "master-data",
      title: "Master Data",
      path: "/app/master-data",
      icon: <SvgIcon name="server" width={16} height={16} />,
      children: [
        {
          id: "master-data-airport",
          title: "Bandara",
          path: "/app/master-data/airport",
        },
        {
          id: "master-data-airlines",
          title: "Maskapai",
          path: "/app/master-data/airlines",
        },
      ],
    },
  ],
} satisfies FeatureMetadata;
