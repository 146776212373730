import AuthFeature from "@/features/Auth";
import CompanyManagement from "@/features/Company";
import ProductManagement from "@/features/Products";
import MasterDataManagement from "@/features/MasterData";
import ClientManagement from "@/features/ClientManagement";
import CustomerManagement from "@/features/CustomerManagement";
import TransactionManagement from "@/features/Transaksi";
import UserManagement from "@/features/UserManagement";
import VoucherManagement from "@/features/VoucherManagement";
import ReservasiManagement from "@/features/Reservasi";
import LandingFeature from "@/features/Landing";

import { type FeatureMetadata } from "@/types/FeatureMetadata";
const featuresRegistry = [
  CompanyManagement,
  MasterDataManagement,
  ProductManagement,
  ReservasiManagement,
  TransactionManagement,
  VoucherManagement,
  ClientManagement,
  CustomerManagement,
  UserManagement,
  AuthFeature,
  LandingFeature,
] satisfies FeatureMetadata[];

export default featuresRegistry;
