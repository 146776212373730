import { useAuth } from "@/libs/hooks/useAuth";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { useEffect } from "react";
import { Navigate, RouteObject } from "react-router-dom";

const LogoutElement = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, []);

  return <Navigate to="/app/login" replace={true} />;
};

export default {
  id: "Auth",
  name: "Authentication Feature",
  placement: "shell",
  routes: [
    {
      path: "/app/login",
      lazy: () => import("./pages/Login"),
    },
    {
      path: "/app/register",
      lazy: () => import("./pages/Register"),
    },
    {
      path: "/app/forgot-password",
      lazy: () => import("./pages/ForgotPassword"),
    },
    {
      path: "/app/logout",
      element: <LogoutElement />,
    },
  ] as RouteObject[],
} satisfies FeatureMetadata;
