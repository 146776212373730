import { useAuth } from "@/libs/hooks/useAuth";
import { BASE_URL_API } from "@/utils/constants";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export const apiClient = ({ ...options }: AxiosRequestConfig) => {
  const instance = axios.create({ ...options });

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      const { data: _, ...res } = response;
      const axiosDataResponse = res;
      return { ...response.data, axiosDataResponse };
    },
    async (error: AxiosError) => {
      const originalConfig = error.config as {
        _retry: boolean;
      } & AxiosRequestConfig;
      const isBearer =
        typeof originalConfig?.headers?.Authorization === "string" &&
        originalConfig.headers.Authorization.includes("Bearer");

      if (isBearer && originalConfig?.baseURL !== useAuth.getState().baseUrl && error.response) {
        if (error.response.status === 401 && !originalConfig?._retry) {
          originalConfig._retry = true;
          try {
            const { accessToken } = await useAuth.getState().refresh();
            if (originalConfig.headers) {
              originalConfig.headers.Authorization = `Bearer ${accessToken}`;
            }

            return instance(originalConfig);
          } catch (_error) {
            useAuth.getState().logout();
            window.location.reload();
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export const apiAuthClient = (baseUrl = BASE_URL_API) =>
  apiClient({
    baseURL: baseUrl,
    headers: {
      Authorization: "Basic dGVsa29tbHA6TFBBZ3JlZTIwMjE=",
    },
  });
export default apiClient;
