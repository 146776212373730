export const APP_NAME = import.meta.env.MERVA_APP_NAME ?? "ABTTravel";

export const APP_NAME_SHORT = import.meta.env.MERVA_APP_NAME_SHORT ?? "ABTTravel";

export const APP_VERSION = import.meta.env.MERVA_APP_VERSION ?? "0.0.0";

export const APP_URL = import.meta.env.MERVA_APP_URL ?? "https://abttravel.online";

export const BASE_URL_API = import.meta.env.MERVA_API_URL ?? "https://api.abttravel.online";

export const isDev = import.meta.env.MODE === "development";
