import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { RouteObject } from "react-router-dom";

export default {
  id: "company-settings",
  name: "Company Settings",
  routes: [
    {
      path: "/app/company/profile",
      lazy: () => import("./pages/CompanyProfilePage"),
    },
    {
      path: "/app/company/bank",
      children: [
        {
          index: true,
          lazy: () => import("./pages/CompanyBankPage"),
        },
        {
          path: ":action/:id?",
          lazy: () => import("./modules/Bank/BankFormPage"),
        },
      ],
    },
    {
      path: "/app/company/payment",
      children: [
        {
          index: true,
          lazy: () => import("./pages/CompanyPaymentPage"),
        },
        {
          path: ":action/:id?",
          async lazy() {
            const { FormPayment } = await import("./modules/Payment");
            return { Component: FormPayment };
          },
        },
      ],
    },
  ] as RouteObject[],
  navigation: [
    {
      id: "company-settings",
      title: "Company Settings",
      icon: <SvgIcon name="settings" width={16} height={16} />,
      children: [
        {
          id: "company-settings-profile",
          title: "Profile",
          path: "/app/company/profile",
        },
        {
          id: "company-settings-bank",
          title: "Bank",
          path: "/app/company/bank",
        },
        {
          id: "company-settings-payment",
          title: "Cara Pembayaran",
          path: "/app/company/payment",
        },
      ],
    },
  ],
} satisfies FeatureMetadata;
