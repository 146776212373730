import { SvgIcon } from "@/components/elements/Icon";
import { FeatureMetadata } from "@/types/FeatureMetadata";
import { RouteObject, json } from "react-router-dom";

const hotelRoutes: RouteObject = {
  path: "/app/products/hotel",
  children: [
    {
      path: "/app/products/hotel",
      children: [
        {
          index: true,
          lazy: () => import("./pages/HotelPage"),
        },
        {
          path: ":action/:id?",
          lazy: () => import("./modules/Hotel/HotelForm"),
        },
      ],
    },
  ],
};

const landArrangementRoutes: RouteObject = {
  path: "/app/products/land-arrangement",
  children: [
    {
      index: true,
      lazy: () => import("./pages/LandArrangementPage"),
      async loader() {
        return json({ title: "Land Arrangments" });
      },
    },
    {
      path: ":action/:id?",
      lazy: () => import("./modules/LandArrangement"),
    },
  ],
};

const tourPackageRoutes: RouteObject = {
  path: "/app/products/tour-package",
  children: [
    {
      index: true,
      lazy: () => import("./pages/TourPackagePage"),
      async loader() {
        return json({ title: "Tour Packages" });
      },
    },
    {
      path: ":action/:id?",
      lazy: () => import("./modules/TourPackage"),
    },
  ],
};

const flightRoutes: RouteObject = {
  path: "/app/products/flight",
  children: [
    {
      index: true,
      lazy: () => import("./pages/FlightPage"),
      async loader() {
        return json({ title: "Flights" });
      },
    },
    {
      path: ":action/:id?",
      lazy: () => import("./modules/Flight/FlightForm"),
    },
  ],
};

export default {
  id: "Products",
  name: "Products",
  routes: [hotelRoutes, landArrangementRoutes, tourPackageRoutes, flightRoutes] satisfies RouteObject[],
  navigation: [
    {
      id: "products",
      title: "Products",
      icon: <SvgIcon name="layout-grid" width={16} height={16} />,
      children: [
        {
          id: "products-hotel",
          title: "Hotel",
          path: "/app/products/hotel",
        },
        {
          id: "products-land-arrangement",
          title: "Land Arrangement",
          path: "/app/products/land-arrangement",
        },
        {
          id: "products-tour-package",
          title: "Tour Package",
          path: "/app/products/tour-package",
        },
        {
          id: "products-flight",
          title: "Flight",
          path: "/app/products/flight",
        },
      ],
    },
  ],
} satisfies FeatureMetadata;
