import { LanguageProvider, QueryProvider, RouteProvider, ThemeProvider } from "@/contexts";

export const RootComponent = () => (
  <QueryProvider>
    <ThemeProvider>
      <LanguageProvider>
        <RouteProvider />
      </LanguageProvider>
    </ThemeProvider>
  </QueryProvider>
);
export default RootComponent;
