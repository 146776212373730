import { Buffer } from "buffer";

export const compose = <T>({ default: C }: { default: T }) => ({
  Component: C,
});

export const isDefaultTrue = (value: unknown) => typeof value === "undefined" || value;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

/**
 * Compares two records based on a key and returns -1, 0, or 1
 * @param recordA - First record to be compared
 * @param recordB - Second record to be compared
 * @param key - The key to be used for comparison
 * @returns -1 if recordA is less than recordB, 0 if they are equal, and 1 if recordA is greater than recordB
 */
export const compareRecords = <T = unknown>(recordA: T, recordB: T, key: keyof T): -1 | 0 | 1 => {
  const lowerCaseA = recordA[key]?.toString().toLowerCase() ?? "";
  const lowerCaseB = recordB[key]?.toString().toLowerCase() ?? "";
  return lowerCaseA < lowerCaseB ? -1 : lowerCaseA > lowerCaseB ? 1 : 0;
};

export function toBase64(input: string): string {
  const buff = Buffer.from(input, "utf-8");
  return buff.toString("base64");
}

export function fromBase64(input: string): string {
  const buff = Buffer.from(input, "base64");
  return buff.toString("utf-8");
}

export function getObjectByString(obj: Record<string, any>, path: string) {
  if (!path) {
    return undefined;
  }
  const keys = path.split(".");
  let value = obj;
  for (const key of keys) {
    value = value[key];
    if (value === undefined) {
      return undefined;
    }
  }
  return value;
}
