import React from "react";
import { createRoot } from "react-dom/client";

import "@/styles/index.scss";
import "@fontsource/inter";
import "@fontsource/poppins";
import { RootComponent } from "./contexts";

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>,
);
